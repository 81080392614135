import { withTheme } from 'emotion-theming'
import React from 'react'
import CtaBarStyles from './CtaBar.styles'

const GlobalStyles = withTheme(() => (
  <>
    <CtaBarStyles />
  </>
))

export default GlobalStyles
