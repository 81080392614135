module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://latino.centurylink.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"removeTrailingSlashes":true,"analyticsId":"GTM-TT2K3DD","brandTokens":["CTL","DTV"],"mapiBrandToken":"EMB","siteName":"latino.centurylink","alternateName":"latino.centurylink.com","siteURL":"https://latino.centurylink.com","defaultTitleTemplate":"","defaultPhone":"8335910792","phoneSymbol":"-","defaultPromoCode":"153613","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046064","vwoProjectID":"894940"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"latino.centurylink","short_name":"latino.centurylink","start_url":"/","background_color":"#48D597","theme_color":"#48D597","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"28a6ace9291e87b01d77ad9c07486849"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
